* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
.flexContainer {
	display: flex;
	justify-content: center;
}

.cardHeader {
	display: flex;
	flex-direction: row;
}
.search {
	display: flex;
	width: 90%;
	margin: 40px auto 15px auto;
}
.inputFicha {
	width: 100px;
	
}
.form-control{
	width: 100%;
}
.form{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1em;
}
.form-input-div{
	width: 40%;
}
.title{
	display: flex;
	justify-content: center;
	margin-top: 1em;
}
.form-btn{
	width: 10%;
	
}

@media (max-width: 600px) {
	.hideOnSmall {
		display: none;
	}
}

@media (min-width: 600px) {
	.search {
		width: 40%;
	}
}
